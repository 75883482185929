@import 'fonts';
@import 'vars';
@import 'normalize';
@import 'animate';

* {
    box-sizing: border-box;
    transition: all 400ms cubic-bezier(0.85, 0, 0.15, 1);
}

img,
video {
    width: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

p {
    margin: 0;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: #000;
}

::selection {
    background: $grey;
}

body {
    font-family: 'inter';
    font-size: 1em;
    background: white;
    color: black;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
    text-rendering: optimizelegibility;
    opacity: 0;
    overflow-x: hidden;
}

input {
    outline: none;
}

fieldset {
    border: none;
    padding: 0;
    margin-top: 70px;

    legend {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        border-bottom: 1px solid #000;
        width: 100%;
        padding-bottom: 15px;
        margin-bottom: 20px;
    }
}

textarea {
    width: 100%;
    resize: none;
    border: none;
    height: 70px;
    border-bottom: 1px solid #000;
    margin-top: 80px;

    @media (max-width: 768px) {
        height: 115px;
    }
}

textarea::placeholder,
input::placeholder {
    color: #bdbcc4;
    font-size: 20px;
}

.bottom-form {
    label {
        padding-bottom: 20px;
        display: block;
        font-size: 20px;
    }

    input {
        display: inline-block;
        margin-right: 7px;
        border: none;
        border-bottom: 1px solid #000;
        padding-bottom: 15px;
        width: 100%;
    }

    .required-fieldset {
        input {
            margin-right: 0px;
        }

        &__items {
            display: flex;
            gap: 30px;
            margin-bottom: 80px;

            @media (max-width: 768px) {
                flex-direction: column;
            }
        }
    }

    .select {
        margin-top: 60px;
        font-size: 22px;
        font-weight: 500;
        display: flex;
        white-space: nowrap;
        gap: 30px;

        @media (max-width: 768px) {
            white-space: wrap;
            flex-direction: column;
        }

        select {
            border: none;
            border-bottom: 1px solid #000;
            padding-bottom: 15px;
            width: 100%;

            option {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    [type='checkbox'] {
        width: 15px;
        height: 15px;
    }

    &__title {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        margin-top: 70px;
    }
}

.container {
    &__wrapper {
        max-width: 1200px;
        width: 100%;
        padding: 0 60px;
        margin: 0 auto;

        @media (max-width: 768px) {
            padding: 0 40px;
        }

        @media (max-width: 468px) {
            padding: 0 20px;
        }
    }
}

.hero {
    padding-top: 150px;
    position: relative;

    @media (max-width: 468px) {
        padding-top: 100px;
    }

    &__left-image {
        position: absolute;
        left: 0;
        top: calc(40% - 350px);

        img {
            height: 700px;
        }

        @media (max-width: 1550px) {
            display: none;
        }
    }

    &__right-image {
        position: absolute;
        right: 0;
        top: calc(40% - 450px);

        img {
            height: 700px;
        }

        @media (max-width: 1550px) {
            display: none;
        }
    }

    &__title {
        font-size: 70px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        max-width: 1050px;
        margin-bottom: 50px;

        @media (max-width: 768px) {
            font-size: 50px;
        }

        @media (max-width: 468px) {
            font-size: 30px;
            margin-bottom: 20px;
        }
    }

    &__text {
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 170%;
        letter-spacing: -0.44px;

        @media (max-width: 468px) {
            font-size: 18px;
        }

        p {
            margin-bottom: 20px;

            &:first-of-type {
                max-width: 900px;
            }

            &:nth-of-type(2) {
                max-width: 700px;
            }
        }
    }

    &__button {
        border-radius: 36px;
        background: #fff;
        box-shadow: 0px 27px 49px 0px rgba(0, 0, 0, 0.08);
        color: $purple;
        font-size: 18px;
        font-weight: 500;
        padding: 20px 40px;
        margin-bottom: 90px;
        margin-top: 60px;
        cursor: pointer;
        max-width: fit-content;

        &:hover {
            background: $purple;

            a {
                color: #fff;
            }
        }

        @media (max-width: 468px) {
            background: #000;
            color: #fff;
            margin-top: 20px;
        }
    }

    &__video {
        video {
            border-radius: 20px;
        }
    }

    &__video.desktop {
        display: block;

        @media (max-width: 468px) {
            display: none;
        }
    }

    &__video.mobile {
        display: none;

        @media (max-width: 468px) {
            display: block;
            margin-bottom: 20px;
        }
    }
}

.create {
    margin-top: 140px;

    @media (max-width: 468px) {
        margin-top: 40px;
    }

    &__title {
        font-size: 96px;
        font-weight: 400;
        max-width: 900px;

        @media (max-width: 1280px) {
            font-size: 80px;
        }

        @media (max-width: 468px) {
            font-size: 40px;
        }
    }

    &__subtitle {
        font-size: 30px;
        font-weight: 300;
        margin-top: 100px;
        margin-bottom: 70px;

        div {
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        @media (max-width: 468px) {
            margin-top: 50px;
            margin-bottom: 60px;
            font-size: 24px;

            div {
                font-size: 24px;
            }
        }
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 80px;
        grid-row-gap: 50px;

        @media (max-width: 1280px) {
            grid-column-gap: 40px;
            grid-row-gap: 25px;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            display: flex;
        }
    }

    &__item {
        font-size: 21px;
        font-weight: 300;
        line-height: 175%;
        letter-spacing: -0.42px;

        span {
            font-weight: 700;
        }

        &::before {
            content: '';
            width: 60px;
            height: 2px;
            background-color: $purple-dark;
            display: block;
            margin-bottom: 20px;
        }
    }
}

.want {
    &__title {
        font-size: 112px;
        font-weight: 700;
        max-width: 900px;
        text-align: center;
        margin: 0 auto;
        margin-top: 190px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            position: absolute;
            z-index: -1;
        }

        @media (max-width: 1280px) {
            font-size: 90px;

            img {
                width: 80%;
            }
        }

        @media (max-width: 1024px) {
            font-size: 70px;
            padding: 0 40px;
        }

        @media (max-width: 768px) {
            font-size: 50px;
        }

        @media (max-width: 468px) {
            font-size: 35px;
        }
    }

    &__subtitle {
        text-align: center;
        margin-top: 140px;
        margin-bottom: 140px;
        font-size: 36px;
        font-weight: 300;

        @media (max-width: 468px) {
            font-size: 25px;
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 80px;
        grid-row-gap: 50px;
        margin: 0 auto;
        max-width: 1320px;

        @media (max-width: 1390px) {
            grid-column-gap: 40px;
            grid-row-gap: 25px;
            padding: 0 50px;
        }

        @media (max-width: 1250px) {
            display: flex;
            flex-direction: column;
        }

        @media (max-width: 768px) {
            padding: 0 20px;
        }
    }

    &__item {
        width: 350px;
        font-size: 22px;
        font-weight: 500;
        line-height: 160%;
        padding-left: 50px;
        position: relative;

        &::before {
            content: url('/img/check.svg');
            position: absolute;
            left: -5px;
        }

        @media (max-width: 1250px) {
            width: 100%;
        }
    }
}

.what {
    margin-top: 140px;
    position: relative;

    @media (max-width: 468px) {
        margin-top: 40px;
    }

    .swiper {
        margin-top: 80px;
    }

    .swiper2 {
        margin-top: 30px;
        overflow: clip;

        @media (max-width: 468px) {
            margin-top: 25px;
        }
    }

    .swiper-free-mode > .swiper-wrapper {
        transition-timing-function: linear !important;
    }

    .swiper,
    .swiper2 {
        &-slide {
            width: 700px;

            @media (max-width: 1280px) {
                width: 400px;
            }

            @media (max-width: 768px) {
                width: 280px;
            }

            @media (max-width: 468px) {
                width: 250px;
            }

            img {
                cursor: grab;
                height: 420px;

                @media (max-width: 1280px) {
                    height: 250px;
                }

                @media (max-width: 768px) {
                    height: 170px;
                }

                @media (max-width: 468px) {
                    height: 150px;
                }
            }
        }
    }

    &__back {
        background-color: $purple-dark;
        height: 700px;
        position: absolute;
        width: 100%;
        z-index: -1;

        @media (max-width: 768px) {
            height: 1300px;
        }
    }

    &__title {
        font-size: 58px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 100px;
        padding-top: 140px;
        color: #fff;

        @media (max-width: 1024px) {
            padding-top: 70px;
            max-width: 300px;
            margin: 0 auto;
            margin-bottom: 50px;
        }

        @media (max-width: 468px) {
            padding-top: 40px;
            font-size: 40px;
        }
    }

    &__icons {
        display: flex;
        justify-content: center;
        column-gap: 80px;

        @media (max-width: 1024px) {
            column-gap: 40px;
            padding: 0 20px;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            row-gap: 40px;
        }

        @media (max-width: 468px) {
            margin-top: 40px;
        }
    }

    &__text {
        color: #fff;
        text-align: center;
    }

    &__icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 25px;

        img {
            max-width: 70px;
        }
    }

    &__images {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        overflow: hidden;
        transition: none;
    }

    &__row {
        display: flex;
        column-gap: 20px;
        transition: none;

        img {
            object-fit: contain;
            height: 335px;
        }
    }
}

.how {
    margin-top: 140px;
    position: relative;

    .container__wrapper {
        padding: 0;

        @media (max-width: 468px) {
            padding: 0 10px;
        }
    }

    @media (max-width: 468px) {
        margin-top: 40px;
    }

    &__left-image {
        position: sticky;
        left: 0;
        top: 0;
        transition: all 2000ms cubic-bezier(0.85, 0, 0.15, 1);

        img {
            width: 300px;
            position: absolute;
            left: 0;
            transition: all 2000ms cubic-bezier(0.85, 0, 0.15, 1);
        }

        &--hidden {
            img {
                left: -100%;
            }
        }

        @media (max-width: 1330px) {
            display: none;
        }
    }

    &__right-image {
        position: sticky;
        right: 0;
        top: 0;
        transition: all 2000ms cubic-bezier(0.85, 0, 0.15, 1);

        img {
            width: 300px;
            position: absolute;
            right: 0;
            transition: all 2000ms cubic-bezier(0.85, 0, 0.15, 1);
        }

        &--hidden {
            img {
                right: -100%;
            }
        }

        @media (max-width: 1330px) {
            display: none;
        }
    }

    .container__wrapper {
        max-width: 840px;
    }

    &__title {
        font-size: 90px;
        font-weight: 500;

        @media (max-width: 768px) {
            font-size: 60px;
        }

        @media (max-width: 468px) {
            font-size: 40px;
            padding-top: 40px;
        }
    }

    &__items {
        margin-top: 50px;
    }

    &__item {
        border-radius: 30px;
        border: 1px solid $grey;
        padding: 20px;
        margin-bottom: 20px;
    }

    .number {
        font-size: 60px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .title {
        font-size: 38px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .text {
        font-size: 20px;
        font-weight: 500;
    }

    .mobile {
        display: none;

        @media (max-width: 768px) {
            display: block;
        }
    }
    &__columns {
        display: flex;
        gap: 20px;

        @media (max-width: 768px) {
            display: none;
        }
    }

    &__column {
        &:nth-of-type(2) {
            margin-top: 150px;
        }
    }
}

.who {
    margin-top: 140px;

    @media (max-width: 468px) {
        margin-top: 40px;
    }

    .container__wrapper {
        max-width: 840px;
        padding: 0px;
        padding-bottom: 110px;
        border-bottom: 1px solid $grey;

        @media (max-width: 768px) {
            padding: 0 40px;
            padding-bottom: 110px;
        }

        @media (max-width: 468px) {
            padding: 0 20px;
            padding-bottom: 110px;
        }
    }

    &__title {
        font-size: 80px;
        font-weight: 700;
        margin-bottom: 50px;

        @media (max-width: 768px) {
            font-size: 70px;
        }

        @media (max-width: 468px) {
            font-size: 40px;
            padding-top: 50px;
        }
    }

    &__list {
        font-size: 21px;
        font-weight: 300;
        line-height: 170%;
    }

    &__item {
        margin-bottom: 20px;
        position: relative;
        padding-left: 30px;

        &::before {
            content: '';
            width: 5px;
            height: 5px;
            background-color: black;
            display: block;
            position: absolute;
            top: 18px;
            border-radius: 50%;
            left: 10px;
        }
    }

    &__numbers {
        display: flex;
        margin-top: 60px;
        column-gap: 100px;

        @media (max-width: 768px) {
            flex-direction: column;
            row-gap: 40px;
            padding-left: 20px;
        }
    }

    &__number {
        .number {
            font-size: 80px;
            font-weight: 700;

            span {
                color: #a500db;
            }
        }

        .text {
            font-size: 20px;
            font-weight: 400;
        }
    }
}

.form {
    margin-top: 140px;

    @media (max-width: 468px) {
        margin-top: 40px;
    }

    .container__wrapper {
        max-width: 840px;
        padding: 0;

        @media (max-width: 950px) {
            padding: 0 15px;
        }
    }

    &__title {
        font-size: 80px;
        font-weight: 700;
        padding-bottom: 30px;

        @media (max-width: 768px) {
            font-size: 60px;
        }

        @media (max-width: 468px) {
            font-size: 40px;
        }
    }

    &__text {
        font-weight: 300;
        line-height: 160%;
        font-size: 24px;

        p {
            margin: 30px 0;
        }
    }

    &__button {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        background-color: $purple;
        width: 100%;
        max-width: 280px;
        height: 65px;
        border-radius: 50px;
        padding: 20px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        margin: 0 auto;
        margin-top: 100px;
        cursor: pointer;

        &:hover {
            background-color: #643ff9;
        }

        &--disable {
            filter: grayscale(1);
            opacity: 0.5;
        }
    }
}

.header {
    position: fixed;
    height: 80px;
    width: 100%;
    border-bottom: 1px solid #e7e7ea;
    background-color: #ffffffd1;
    top: 0;
    z-index: 10;
    backdrop-filter: blur(20px);

    img {
        object-fit: contain;
    }

    &__logo {
        width: 100px;
    }

    &__humburger {
        width: 30px;
        cursor: pointer;
    }

    &__wrapper {
        height: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 240px);
        margin: 0 auto;

        @media (max-width: 1330px) {
            width: calc(100% - 130px);
        }

        @media (max-width: 768px) {
            width: calc(100% - 90px);
        }

        @media (max-width: 468px) {
            width: calc(100% - 50px);
        }
    }

    &__menu {
        transform: translateX(100%);
        position: absolute;
        top: 0;
        right: 0;
        background: black;
        color: #fff;
        max-width: 380px;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;

        img {
            width: 30px;
            margin: 50px;
        }

        &--active {
            transform: translateX(0px);
        }
    }

    &__close {
        text-align: right;

        img {
            cursor: pointer;
        }
    }

    &__items {
        margin: 0 50px;
        margin-bottom: 50px;
    }

    &__item {
        margin-bottom: 50px;
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;

        &:hover {
            a {
                text-decoration: underline;
            }
        }
    }
}

.footer {
    background-color: #000;
    color: #fff;

    &__wrapper {
        width: 100%;
        max-width: 1470px;
        margin: 0px auto;
        margin-top: 150px;
        padding: 50px 40px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        &:first-of-type {
            border-bottom: 1px solid #bdbcc44d;
            padding-bottom: 80px;
        }

        @media (max-width: 900px) {
            flex-direction: column;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        font-size: 20px;
        font-weight: 400;

        .title {
            font-size: 22px;
            font-weight: 700;
            line-height: 150%;
        }

        .text {
            font-size: 20px;
            font-weight: 300;
            line-height: 150%;
            max-width: 350px;
        }

        img {
            max-width: 100px;
            margin-bottom: 50px;
        }
    }
}
